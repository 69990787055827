import axios from "./axios.js"

export class VendorMaster {

    static getVendors() {
        return axios.get("/vendors");

    }
    static getVendor(vendorId) {
        let defaultURL = `/vendors/${vendorId}`;
        return axios.get(defaultURL);
    }
    static createVendor(vendor) {
        let defaultURL = `/vendors`;
        return axios.post(defaultURL, vendor);
    }
    static updateVendor(vendor, vendorId) {
        let defaultURL = `/vendors/${vendorId}`;
        return axios.put(defaultURL, vendor);
    }
    static deleteVendor(vendorId) {
        let defaultURL = `/vendors/${vendorId}`;
        return axios.delete(defaultURL);
    }

}