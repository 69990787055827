import axios from "axios";
const token = localStorage.getItem('authToken');
const instance = axios.create({
  // baseURL: 'http://localhost:8080/api/',
  baseURL: 'https://api.powerfasteners.in/api/',
  headers: {
    "Content-Type": "multipart/form-data",
    "Authorization": `Bearer ${token}`,
    "Access-Control-Allow-Origin" : "*",
    "Access-Control-Allow-Methods" : "GET, POST, OPTIONS, PUT, DELETE",
    // "Origin" : "https://api.powerfasteners.in"
  },
});

export default instance;
