import axios from "./AxiosFile.js"

export class SendMails {


    static sendMailHtml(maildata) {
        let defaultURL = `/sendmail`;
        return axios.post(defaultURL, maildata);
    }


}