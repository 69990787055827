<template>
    <div class="container mb-3">
        <!-- {{ dataContent }} -->
        <div class="row">
            <div class="col-md-6">
                <h3>Mail Send Master</h3>
            </div>
            <div class="col-md-6">
                <!-- <pre>{{ form }} {{ query }}</pre> -->

            </div>
        </div>
        <div class="row">
            <div class="col">

                <form @submit="onSubmit" class="country-from vendor-mas" enctype="multipart/form-data">
                    <div class="row">
                        <!-- {{ subContact }} -->
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="subContact" v-model="subContact" id="flexCheckChecked" checked>
                            <label class="form-check-label" for="flexCheckChecked">
                                Include sub Contact
                            </label>
                        </div>
                    </div>
                    <div class="row">
                        <!-- <div class="col-md-6 mb-3">
                            <label for="group" class="form-label">Vendor Email Tags</label>
                            <Multiselect v-model="vendorTags" :options="options" mode="tags" :searchable="true"
                                :createTag="true" placeholder="Company Name" />
                        </div> -->


                        <div class="col-md-5 mb-3">

                            <label for="filterId" class="form-label">Mail Recipient * </label>
                            <select v-model="filterId" id="filterId" class="form-select"
                                aria-label="Default select example">
                                <option selected Disabled>Select Mail Recipient</option>
                                <option v-for="filter in filters" :key="filter.id" :value="filter.id">{{
                                    filter.name
                                }}</option>
                            </select>
                        </div>
                        <div class="col-md-5 mb-3">
                            <label for="mailSubject" class="form-label">Mail Template *</label>
                            <select v-model="templateId" @change="editdata()" class="form-select"
                                aria-label="Default select example">
                                <option selected Disabled>Select Mail Template</option>
                                <option v-for="template in templates" :key="template.id" :value="template.id">{{
                                    template.mailSubject
                                }}</option>
                            </select>
                        </div>

                        <div class="col-md-2 mb-3">
                            <button type="button" id="btnViewFilter" @click="getVendorsId()" class="btn btn-primary"
                                data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                View Recipient
                            </button>
                        </div>
                    </div>

                    <!-- <div class="col-md-2 mb-3">
                            <button type="button" id="btnViewTemplate" @click="editdata()" class="btn btn-primary"
                            >
                                Load Template
                            </button>
                        </div> -->
                    <div class="row">
                        <div class="col-md-6 mb-3">
                            <label for="mailSubject" class="form-label">Mail Subject </label>
                            <input type="text" v-model="mailSubject" class="form-control" id="mailSubject"
                                placeholder="Mail Subject" required>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 mb-3">

                            <label for="name" class="form-label lbl-bg">Mail Content, For Dynamic fields : { {
                                companyName }
                                },{ { contactPerson } },{ { address1 } },{ { address2 } },{ { cityName } },{ {emailId }
                                },{ { mobileNo} },{ {groupTags } } without Space</label>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 mb-3">
                            <ckeditor id="name" :editor="editor" v-model="dataContent" :config="editorConfig">
                            </ckeditor>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-4 mb-3">
                            <div class="imput-group">
                                <label for="file" class="form-label"> <i class="fa fa-paperclip" aria-hidden="true"></i>
                                    Attachments
                                </label>
                                <input class="form-control form-control-sm" @change="onFileChange" type="file" refs="file"
                                    multiple>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <label for="" class="form-label"></label>
                            <div class="mr-top">
                                <!-- <button class="btn btn-success"><i class="fa fa-upload"
                                        aria-hidden="true"></i> Upload
                                </button> -->
                            </div>
                        </div>
                        <div class="col-md-6">
                            Uploaded File List :
                            <p class="text-primary text-decoration-underline font-weight-bold" v-html="filename"></p>

                        </div>
                    </div>


                    <div class="row">
                        <div class="col-md-2"> <input type="submit" :value="btnText" class="btn  btn-primary"
                                data-bs-toggle="modal" data-bs-target="#template" /></div>
                        <div class="col-md-2"><input @click="sendmail()" type="button" value="Send Mail"
                                class="btn  btn-primary" /></div>
                    </div>


                </form>
            </div>
        </div>
    </div>
    <div class="container" style="display: none;">
        <div class="table-responsive">
            <table class="table" ref="mailTemplateTable">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Mail Subject</th>
                        <th>dataContent</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="template in templates" :key="template.id">
                        <td>{{ template.id }}</td>
                        <td>{{ template.mailSubject }}</td>
                        <td>{{ template.dataContent }}</td>
                        <td><button class="btn btn-success" @click="editdata(template.id)">Load </button>
                            <button class="btn btn-danger" @click="deletedata(template.id)">Delete</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="container" v-if="loading">
        <div class="row">
            <div class="col">
                <Spinner />
            </div>
        </div>
    </div>

    <!-- model  -->
    <!-- Button trigger modal -->


    <!-- Filter Modal -->
    <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="staticBackdropLabel">Recipient data</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body" id="filterData">
                    <div class="table-responsive">
                        <table class="table" ref="mailFilterTable">
                            <thead>
                                <tr>

                                    <th>Company name</th>
                                    <th>Contact Person</th>
                                    <th>Mail Id</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="vendor in vendorFilter" :key="vendor.id">

                                    <td>{{ vendor.companyName }}</td>
                                    <td>{{ vendor.contactPerson }}</td>
                                    <td>{{ vendor.emailId }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <!-- <button type="button" class="btn btn-primary">Understood</button> -->
                </div>
            </div>
        </div>
    </div>
    <!-- filter model end -->
    <!-- Tempalte Preview Modal -->
    <div class="modal fade" id="template" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="templateLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="templateLabel">Mail Preview </h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body" id="templateData">
                    <b>Subject :-</b>
                    <div class="subjec mb-3" style="border: 1px solid gray;padding: 5px;" v-html="mailSubject"></div>
                    <b>Mail Body :-</b>
                    <div class="content" style="border: 1px solid gray;padding: 5px;" v-html="dataContent"></div>
                    <b>Mail Attachments :-</b>
                    <p class="text-primary text-decoration-underline font-weight-bold" v-html="filename"></p>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <!-- <button type="button" class="btn btn-primary">Understood</button> -->
                </div>
            </div>
        </div>
    </div>
    <!-- Template Preview model end -->
</template>
<style src="@vueform/multiselect/themes/default.css"></style>
<script>
import { MailTemplate } from '../services/MailTemplate';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import Multiselect from '@vueform/multiselect'
import { VendorMaster } from '../services/VendorMaster'
import { SendMails } from '../services/SendMails';
import { MailFilters } from '../services/MailFilters'
import Spinner from '../components/Spinner.vue';
import { VendorFilter } from '../services/VendorFilter';
import $ from "jquery";
import dt from "datatables.net";
import dt1 from "datatables.net";

export default {
    name: 'MailTemplate',
    components: {
        // Multiselect,
        Spinner
    },
    data() {
        return {
            loading: false,
            templates: [],
            id: '',
            mailSubject: '',
            // vendorTags: [],
            vendors: [],
            filterId: '',
            templateId: '',
            vendorFilter: [],
            subContact : '',
            filters: [],
            btnText: 'Preview',
            errorMasage: null,
            editor: ClassicEditor,
            dt: dt,
            dt1: dt1,
            dataContent: '<p>Content of the editor.</p>',
            editorConfig: {
                // The configuration of the editor.
            },
            filename: '',
            files: '',
            success: ''
        }
    },
    mounted() {
        this.dt = $(this.$refs.mailTemplateTable).DataTable();
        this.dt = $(this.$refs.mailFilterTable).DataTable();
    },
    watch: {
        templates() {
            this.dt.destroy();
            this.$nextTick(() => {
                this.dt = $(this.$refs.mailTemplateTable).DataTable()
            })
        }, vendorFilter() {
            this.dt.destroy();
            this.$nextTick(() => {
                this.dt = $(this.$refs.mailFilterTable).DataTable()
            })
        }

    },
    async created() {
        try {
            this.loading = true
            let response = await VendorMaster.getVendors();
            this.vendors = response.data;
            this.options = this.vendors.map(function (vendor) {
                return {
                    value: vendor.emailId,
                    label: vendor.companyName
                }

            });
            this.loading = false
        }
        catch (error) {
            this.errorMasage = error;
            this.loading = false
        }
        try {
            this.loading = true
            let response = await MailTemplate.getMailTemplates()
            this.templates = response.data;

            // console.log(this.templates);
            this.loading = false
        }
        catch (error) {
            this.errorMasage = error;
            this.loading = false
        }
        try {
            this.loading = true
            let response = await MailFilters.getMailFilters()
            this.filters = response.data;

            // console.log(this.filters);
            this.loading = false
        }
        catch (error) {
            this.errorMasage = error;
            this.loading = false
        }
    }, methods: {
        onFileChange(e) {
            this.filename = "";
            // console.log(e.target.files);
            const formData = new FormData();
            var totalFileSize = 0;
            for (var i = 0; i < e.target.files.length; i++) {
                let file = e.target.files[i];
                // if (!file) {
                //     e.preventDefault();
                //     alert('No file chosen');
                //     return;
                // }
                const fileTypes = ['image/png', 'image/jpeg', 'application/pdf'];
                // alert(fileTypes.includes(file.type));
                if (fileTypes.includes(file.type)) {
                    e.preventDefault();
                    // alert('file valide');
                    // return ;
                } else {
                    // e.preventDefault();
                    // alert('file not valide');
                    // continue;
                }
                totalFileSize = totalFileSize + file.size;
                // alert(totalFileSize + ' '+ (30* (1024 * 1024)));
                if (totalFileSize > (30 * (1024 * 1024))) {
                    e.preventDefault();
                    alert('File too big (> 1MB)');
                    this.filename = this.filename + "Total Files Size: " + ((totalFileSize - file.size) / (1024 * 1024)).toFixed(2);
                    return;
                }
                formData.append('files[' + i + ']', file);
                this.filename = this.filename + "Selected File: " + (i + 1) + "  " + e.target.files[i].name + ' File Size :-' + ((e.target.files[i].size) / (1024 * 1024)).toFixed(2) + "MB,<br/>";
            }
            this.filename = this.filename + "Total Files Size: " + (totalFileSize / (1024 * 1024)).toFixed(2);
            this.files = e.target.files;
        },
        async onSubmit(e) {
            e.preventDefault()

            if (!this.mailSubject) {
                alert('Please Enter Subject')
                return
            }

            // ClassicEditor.instances.IDofEditor.insertText('some text here');
            // ClassicEditor.instances['idofeditor'].insertText(this.mailSubject);

            if (!this.dataContent) {
                alert('Please Enter Some Content')
                return
            }
            const form = {
                "mailSubject": this.mailSubject,
                "dataContent": this.dataContent,
            }
            // const response = await MailTemplate.createCountry(form);
            if (this.btnText === 'Save123') {
                try {
                    this.loading = true;
                    const response = await MailTemplate.createMailTemplate(form);
                    // console.log(response);
                    if (response.data.status == 'success') {
                        alert('created');
                    } else {
                        alert('error');
                    }
                    this.dataContent = "";
                    this.mailSubject = "";
                    this.loading = false;
                } catch (error) {
                    // console.log("this is error ", error)
                    if (error.response.status === 422) {
                        console.log(error.response.data.errors.dataContent);

                    }
                    this.loading = false;
                    // console.log(errors.value.dataContent);
                }
            } //else {
            //     // alert('update');
            //     try {
            //         this.loading = true;
            //         const response = await MailTemplate.updateMailTemplate(form, this.id);
            //         // console.log(response);
            //         if (response.data.status == 'success') {
            //             // alert('Updated');
            //         } else {
            //             alert('error');
            //         }
            //         this.dataContent = "";
            //         this.mailSubject = "";
            //         this.loading = false;
            //     } catch (error) {
            //         // console.log("this is error ", error)
            //         if (error.response.status === 422) {
            //             console.log(error.response.data.errors.dataContent);

            //         }
            //         this.loading = false;
            //         // console.log(errors.value.dataContent);
            //     }
            //     this.btnText = "Save";
            // }
            // this.loading = true
            // let response = await MailTemplate.getMailTemplates()
            // this.templates = response.data;
            this.loading = false


        },
        async editdata() {
            this.loading = true;
            let id = this.templateId
            // alert(id);
            // this.btnText = "Update";
            const response = await MailTemplate.getMailTemplate(id)
            this.id = id;
            this.mailSubject = response.data.mailSubject;
            this.dataContent = response.data.dataContent;
            // console.log(response.data.dataContent);
            // this.btnText = "Save";
            this.loading = false;

        },
        async deletedata(id) {
            let result = await this.$swal({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            });
            // alert(result.isConfirmed);
            if (result.isConfirmed) {
                this.loading = true;
                const resDel = await MailTemplate.deleteMailTemplate(id);
                // console.log(resDel);
                if (resDel.status == 204) {
                    this.$swal(
                        'Mail Template Deleted',
                        resDel.data.status,
                        'success'
                    );
                }

            }

            // alert(id);

            // this.loading = true;
            let response = await MailTemplate.getMailTemplates();
            this.templates = response.data;
            this.loading = false;
        },
        async sendmail() {
            try {
                
                
                
                this.loading = true;
                const form = new FormData();
                for (var i = 0; i < this.files.length; i++) {
                    let file = this.files[i];
                    form.append('files[' + i + ']', file);
                }

                form.append("mailSubject", this.mailSubject);
                form.append("dataContent", this.dataContent);
                form.append("filterId", this.filterId);
                form.append("templateId", this.templateId);
                form.append("subContact",this.subContact);

                // console.log("data", form);
                let response = await SendMails.sendMailHtml(form);
                // console.log(response);
                this.$swal(
                    'Mail Sent in Job Queue wait for sending !',
                    response.data.status,
                    'success'
                );
                // console.log("response", response);
                this.loading = false;
            } catch (error) {
                this.loading = false;
                if (error.response.status == 413) {
                    this.$swal(
                        'Mail Content is Too Large !',
                        'Mail Not Sent',
                        'error'
                    );
                }
                console.log(error);
            }
        },
        async getVendorsId() {
            try {


                this.loading = true;
                // this.vendorTags ="1,2,3,4,8".split(",");
                const form = {
                    "filterId": this.filterId,
                }
                let response = await VendorFilter.getVendorFilter(form);
                this.vendorFilter = response.data;
                // console.log(this.vendorFilter);
                // alert('ok');
                this.loading = false;
            } catch (error) {
                // console.log("this is error ", error)
                if (error.response.status === 404) {
                    console.log(error.response.data.message);
                    alert(error.response.data.message);
                    this.vendorFilter = error.response.data.message;
                }
                this.loading = false;
                // console.log(errors.value.dataContent);
            }
        }
    },
}
</script>
<style scoped>
.vendor-mas {
    background: #fbfbfb;
    border: solid 1px #d2d2d2;
    padding: 30px;
    margin: 20px 0px;
}

.vendor-mas .form-control {
    padding: 12px 10px;
    border-radius: 0;

}

.multiselect {
    border-radius: 0;
    padding: 4px 10px;
}

.btn-bottom {
    text-align: center;
}

.vendor-mas .btn.btn-primary {
    border-radius: 3px;
    padding: 10px 25px;
    display: inline-block;
    font-size: 18px;
    font-weight: 700;
}

.lbl-bg {
    background: #e5f5ff;
    border: solid 1px #d2d2d2;
    padding: 10px;
}

.vendor-mas .form-select {
    padding: 12px 10px;
    border-radius: 0;
}

.mr-top {
    padding-top: 7px;
}

.mr-top .btn.btn-success {
    border-radius: 3px;
    padding: 10px 25px;
    display: inline-block;
    font-size: 18px;
    font-weight: 700;
}

.table>thead {
    vertical-align: bottom;
    background: lightskyblue;
}

tbody tr td {
    border-right: 1px solid #a7daf9;
}

tbody tr td:first-child {
    border-left: 1px solid #a7daf9;
}


tr.odd {
    background-color: #FFF;
    vertical-align: middle;
}

tr.even {
    background-color: #e5f5ff;
    vertical-align: middle;
}
</style>