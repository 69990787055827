import axios from "./axios.js"

export class VendorFilter {

        
    static getVendorFilter(vendor) {
        let defaultURL = `/vendorfilter`;
        return axios.post(defaultURL, vendor);
    }
    

}